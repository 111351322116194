import { ChangeEvent, useState } from "react";
import { io } from "socket.io-client";
import styled, { createGlobalStyle } from "styled-components";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export type VehicleType = {
  veh_no: string;
  veh_ts: string;
  shift: string;
  direction: string;
  next_stop: string;
  lat: number;
  lon: number;
};
type FiltersType = {
  onRoute: boolean;
  showBuses: boolean;
  showTrams: boolean;
  text: string;
};
const InfoContainer = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
const Input = styled.input`
  border: 1px #0f0f0f solid;
  border-radius: 4px;
  color: #0f0f0f;
  padding: 8px;
`;
const socket = io("brygady.komunikacja.info");
// const socket = io("localhost:8000");
socket.on("connect", () => {
  console.log(socket.id); // x8WIv7-mJelg7on_ALbx
});
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif;
  }
  a { color: black}
`;

const columns = [
  {
    name: "Vehicle number",
    selector: (row: VehicleType) => row.veh_no,
    center: true,
    sortable: true,
    width: "150px",
    format: (row: VehicleType) => (
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${row.lat},${row.lon}`}
        target="_blank"
        rel="noreferrer"
      >
        {row.veh_no}
      </a>
    ),
  },
  {
    name: "Line",
    selector: (row: VehicleType) => row.shift,
    sortable: true,
    width: "100px",
  },
  {
    name: "Direction",
    selector: (row: VehicleType) => row.direction,
    sortable: true,
    sortFunction: (a: VehicleType, b: VehicleType) =>
      a.direction.localeCompare(b.direction),
  },
  {
    name: "Next stop",
    selector: (row: VehicleType) => row.next_stop,
    sortable: true,
  },
  {
    name: "Last seen online",
    selector: (row: VehicleType) => row.veh_ts,
    sortable: true,
    format: (row: VehicleType) =>
      row.veh_ts ? dayjs(row.veh_ts).fromNow() : "-",
  },
];

export const App = () => {
  const [vehicles, setVehicles] = useState<VehicleType[]>([]);
  const [date, setDate] = useState<string>("");
  const [filters, setFilters] = useState<FiltersType>({
    onRoute: true,
    showBuses: true,
    showTrams: true,
    text: "",
  });
  socket.on("vehicles", ({ vehicles, date }) => {
    setVehicles(vehicles);
    setDate(date);
  });
  const handleOnRouteFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, onRoute: e.target.checked });
  };
  const handleTextFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, text: e.target.value });
  };
  const handleShowBusesFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, showBuses: e.target.checked });
  };
  const handleShowTramsFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, showTrams: e.target.checked });
  };
  const dataFilter = (vehicles: VehicleType[]) => {
    let filtered = filters.onRoute
      ? vehicles.filter((vehicle) => vehicle.shift !== "off")
      : vehicles;
    filtered = filtered.filter(
      (vehicle) =>
        vehicle.veh_no.toLowerCase().indexOf(filters.text.toLowerCase()) !== -1,
    );

    if (!filters.showTrams) {
      filtered = filtered.filter(vehicle => vehicle.veh_no[0] !== 'H' && vehicle.veh_no[0] !== 'R')
    }

    if (!filters.showBuses) {
      filtered = filtered.filter(vehicle => vehicle.veh_no[0] !== 'P' && vehicle.veh_no[0] !== 'B' && vehicle.veh_no[0] !== 'D')
    }

    return filtered;
  };
  return (
    <>
      <GlobalStyle />
      <InfoContainer>
        <div>
          Last update: {date}
          <p
            style={{
              color: "#aaaaaa",
              fontSize: 12,
              margin: "4px 0",
              textAlign: "center",
            }}
          >
            (auto refresh on every 10s)
          </p>
        </div>
        <div>
          <label htmlFor="on-routes">On route only</label>
          <input
            type="checkbox"
            id="on-routes"
            onChange={handleOnRouteFilterChange}
            checked={filters.onRoute}
            style={{ cursor: "pointer", margin: "0 1em" }}
          />
        </div>
        <div>
          <label htmlFor="show-buses">Show buses</label>
          <input
            type="checkbox"
            id="show-buses"
            onChange={handleShowBusesFilterChange}
            checked={filters.showBuses}
            style={{ cursor: "pointer", margin: "0 1em" }}
          />
        </div>
        <div>
          <label htmlFor="show-trams">Show trams</label>
          <input
            type="checkbox"
            id="show-trams"
            onChange={handleShowTramsFilterChange}
            checked={filters.showTrams}
            style={{ cursor: "pointer", margin: "0 1em" }}
          />
        </div>
        <div>
          <Input
            type="text"
            value={filters.text}
            onChange={handleTextFilterChange}
            placeholder="Search vehicle..."
          />
        </div>
      </InfoContainer>
      {/* <SearchBar /> */}
      <DataTable columns={columns} data={dataFilter(vehicles)} dense striped />
      {/* {vehicles.map((vehicle) => (
        <div>
          {vehicle.veh_no} - {vehicle.shift} - {vehicle.direction} -{" "}
          {vehicle.next_stop}
        </div>
      ))} */}
    </>
  );
};
